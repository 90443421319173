import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import * as loginActions from '../../actions/login/LoginActions'
import './AccountLogin.css'
import { Button, Form, Col } from 'react-bootstrap'
import AuthService from '../../services/auth/AuthService'
import NotificationSystem from 'react-notification-system'

class AccountLogin extends React.Component {

  constructor(props) {
    super(props)
    this.notificationSystem = React.createRef()
    this.state = {
      redirectToReferrer: false,
      email: null,
      password: null
    }
    this.handleEmailChange = this.handleEmailChange.bind(this)
    this.handlePasswordChange = this.handlePasswordChange.bind(this)
    this.login = this.login.bind(this)
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.token && this.props.authedAcct) {
      AuthService.loginUser(this.props.authedAcct, this.props.token)
      this.props.history.push('/users')
    } else if (this.props.invalidLogin) {
      this.invalidLoginNotification()
      this.props.clearInvalidLoginState()
    }
  }

  invalidLoginNotification = () => {
    const notification = this.notificationSystem.current;
    notification.clearNotifications()
    notification.addNotification({
      message: 'Invalid credentials',
      level: 'warning',
      position: 'tc'
    })
  }

  handleEmailChange(event) {
    this.setState({...this.state, email: event.target.value});
  }

  handlePasswordChange(event) {
    this.setState({...this.state, password: event.target.value});
  }

  login = (e) => {
    e.preventDefault()
    this.props.loginUser(this.state.email, this.state.password)
  }

  render() {
    return (
      <div className="login-container">
        <Form>
          <Form.Group controlId="formHorizontalEmail">
            <Col sm={2}>
              Email
            </Col>
            <Col sm={10}>
              <Form.Control type="email" placeholder="Email" onChange={this.handleEmailChange}/>
            </Col>
          </Form.Group>

          <Form.Group controlId="formHorizontalPassword">
            <Col sm={2}>
              Password
            </Col>
            <Col sm={10}>
              <Form.Control type="password" placeholder="Password" onChange={this.handlePasswordChange}/>
            </Col>
          </Form.Group>

          <Form.Group>
            <Col sm={10}>
              <Button type="submit" onClick={this.login}>Sign in</Button>
            </Col>
          </Form.Group>
        </Form>

        <NotificationSystem ref={this.notificationSystem} />
      </div>
    )
  }

}

AccountLogin.propTypes = {
  loginActions: PropTypes.object,
  authedAcct: PropTypes.object,
  token: PropTypes.string,
  invalidLogin: PropTypes.bool
}

function mapStateToProps(state) {
  return {
    token: state.login.token,
    authedAcct: state.login.authedAcct,
    invalidLogin: state.login.invalidLogin
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(loginActions, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountLogin)
