import React, { Component } from 'react'
import './App.css'
import Header from './components/Header'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import routes from "./config/routes"
import RouteWithSubRoutes from "./components/routing/RouteWithSubRoutes"
import UsersList from './components/users/UsersList'

class App extends Component {
  render() {
    return (
      <Router>
        <div className="app-container">
          <Header props={this.props.history} />
          <div id="app" className="app">
            <Route path="/users" component={ UsersList } />
            <Switch>
              {routes.map((route, i) => (
                <RouteWithSubRoutes key={i} {...route} />
              ))}
            </Switch>
          </div>
        </div>
      </Router>
    );
  }
}

export default App;
