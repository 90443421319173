import { combineReducers } from 'redux'
import users from './users/UsersReducer'
import user from './users/UserReducer'
import userLists from './users/lists/UserListsReducer'
import login from './login/LoginReducer'
import stats from './stats/StatsReducer'

const rootReducer = combineReducers({
  users, user, login, stats, userLists
})

export default rootReducer
