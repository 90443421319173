import * as types from './UserActionTypes'
import { API_ROOT_V1, API_KEY } from '../../config/api-config'
import linkParser from 'parse-link-header'

export function receiveUsers(obj) {
  return {
    type: types.RECEIVE_USERS,
    users: obj.users,
    page: obj.page,
    next_page: obj.next_page,
    prev_page: obj.prev_page
  }
}

export function fetchUsers(page, qry=null) {
  let next_page
  let prev_page
  let endpoint = qry && qry.length > 0 ?
                 `${API_ROOT_V1}/search/users?qry=${qry}&page=${page}&per_page=15` :
                 `${API_ROOT_V1}/users?page=${page}&per_page=15`

  return dispatch => {
    return fetch(endpoint, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'X-Auth-Token': API_KEY,
        'Accept': 'application/json'
      }
    })
    .then(res => {
      let pagination_links = linkParser(res.headers.get('Link'))
      if (pagination_links) {
        let next = pagination_links['next']
        if (next) {
          next_page = Number(next['page'])
        }
        let prev = pagination_links['prev']
        if (prev) {
          prev_page = Number(prev['page'])
        }
      }
      return res.json()
    })
    .then(json => {
      dispatch(receiveUsers({
        users: json.users,
        page: page,
        next_page: next_page,
        prev_page: prev_page
      }))
    })
  }
}
