import React from 'react'
import './UserPurchases.css'

function UserPurchase(props) {
  const purchase = props.purchase
  return (
    <tr>
      <td>{purchase.product_id}</td>
      <td>•••••••••••</td>
      <td>{purchase.tid.length > 15 ? purchase.tid.slice(0, 15) + '...' : purchase.tid}</td>
      <td>{purchase.psid}</td>
      <td>{purchase.created_at}</td>
    </tr>
  )
}

export default UserPurchase
