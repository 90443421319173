import * as types from './LoginActionTypes'
import { API_ROOT_V2 } from '../../config/api-config'

export function receiveApiKey(apiKey) {
  return {
    type: types.RECEIVE_USER_ACCT,
    invalidLogin: false,
    authedAcct: apiKey.user,
    token: apiKey.token
  }
}

export function invalidLoginHandler() {
  return {
    type: types.LOGIN_INVALID,
    invalidLogin: true,
    authedAcct: null,
    token: null
  }
}

export function resetLoginState() {
  return {
    type: types.RESET_LOGIN_STATE,
    invalidLogin: false,
    authedAcct: null,
    token: null
  }
}

export function clearInvalidLoginState() {
  return (dispatch) => {
    dispatch(resetLoginState())
  }
}

export function loginUser(email, password) {
  return (dispatch) => {
    return fetch(`${API_ROOT_V2}/auth/login`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({login: email, password: password})
    })
    .then(res => res.json())
    .then(json => {
      let apiKey = json.api_key
      if (apiKey === null || apiKey.token === undefined || apiKey.token === null) {
        dispatch(invalidLoginHandler())
      } else {
        dispatch(receiveApiKey(apiKey))
      }
    })
    .catch(err => {
      dispatch(invalidLoginHandler())
    })
  }
}
