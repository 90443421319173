import AuthService from '../services/auth/AuthService'

let backendHost = 'https://api.drivingfordollarsapp.com'
const apiVersion1 = 'v1'
const apiVersion2 = 'v2'

// switch (process.env.REACT_APP_STAGE) {
//   case 'dev':
//     backendHost = 'http://localhost:5000'
//     break
//   case 'qa':
//     backendHost = 'https://apiqa.drivingfordollarsapp.com'
//     break
//   case 'prod':
//     backendHost = 'https://api.drivingfordollarsapp.com'
//     break
//   default:
//     backendHost = process.env.REACT_APP_BACKEND_HOST || 'http://localhost:5000'
// }

export const API_ROOT_V1 = `${backendHost}/${apiVersion1}`
export const API_ROOT_V2 = `${backendHost}/${apiVersion2}`
export const API_KEY = AuthService.acctToken()
