import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as listActions from '../../../actions/users/lists/ListActions'
import './UserLists.css'
import UserListsItem from './UserListsItem'
import PropTypes from 'prop-types'
import { ListGroup,
         Pagination, 
         Badge,
         Card,
         CardDeck} from 'react-bootstrap'

class UserLists extends React.Component {

  constructor(props) {
    super(props)
    this.handleNext = this.handleNext.bind(this)
    this.handlePrev = this.handlePrev.bind(this)
    this.renderNoLists = this.renderNoLists.bind(this)
    this.renderPagination = this.renderPagination.bind(this)
    this.renderLists = this.renderLists.bind(this)
    this.reRunList = this.reRunList.bind(this)
    this.deleteList = this.deleteList.bind(this)
    this.recoverList = this.recoverList.bind(this)
  }

  componentDidMount() {
    this.props.fetchLists(this.props.match.params.id, 1)
  }

  // componentDidUpdate(prevProps, prevState) {
  //   console.log("Component did update prevProps: ", prevProps);
  //   console.log("Component did update props: ", this.props);
  // }

  handleNext() {
    this.props.fetchLists(this.props.match.params.id, this.props.page + 1)
  }

  handlePrev() {
    this.props.fetchLists(this.props.match.params.id, this.props.page - 1)
  }

  reRunList(list_id) {
    const user_id = this.props.match.params.id
    console.log(`Re-running list with user id ${user_id} and list id ${list_id}`);
    this.props.reprocessList(user_id, list_id)
  }

  deleteList(list_id) {
    const user_id = this.props.match.params.id
    console.log(`Deleting list with user id ${user_id} and list id ${list_id}`);
    this.props.deleteList(user_id, list_id)
  }

  recoverList(list_id) {
    const user_id = this.props.match.params.id
    console.log(`Recovering list with user id ${user_id} and list id ${list_id}`);
    this.props.updateList(user_id, list_id, {deleted: false})
  }

  renderData(lists) {
    const { user } = this.props
    lists = lists || []
    if (lists.count === 0) {
      return this.renderNoUsers()
    } else {
      const listsItems = lists.map((list) =>
        <UserListsItem key={list.id.toString()}
                      list={list}
                      reRunList={this.reRunList}
                      deleteList={this.deleteList}
                      recoverList={this.recoverList} />
      )
      let finalItems = []
      let currentIndex = 0
      listsItems.forEach((val) => {
        switch (currentIndex) {
          case 0:
            finalItems.push([val])
            currentIndex += 1
            break
          case 1:
            finalItems[finalItems.length - 1].push(val)
            currentIndex += 1
            break
          case 2:
            finalItems[finalItems.length - 1].push(val)
            currentIndex = 0
            break
          default: break
        }
      })
      const mappedFinalItems = finalItems.map((sub_lists, i) => 
        <CardDeck key={i}>
          {sub_lists}
        </CardDeck>
      )
      return (
        <div className="lists-container">
          <Card>
            <Card.Header>User Account Details</Card.Header>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <Badge pill variant="success">{user.id}</Badge> {user.first_name} {user.last_name} <br/>
                {user.email}
              </ListGroup.Item>
            </ListGroup>
          </Card>

          { mappedFinalItems }


          {/* <Card>
            <Card.Header>User Lists</Card.Header>
            <ListGroup variant="flush">
              {listsItems}
            </ListGroup>
          </Card> */}

          {lists.count === 0 ? '' : this.renderPagination()}          
        </div>
      )
    }
  }

  renderLists(lists) {
    return lists.forEach((sub_lists) => 
        <CardDeck>
          {sub_lists}
        </CardDeck>
    )
  }

  renderNoLists() {
    return <div className="">No Lists</div>
  }

  renderPagination() {
    const { prev_page, next_page } = this.props
    return (
      <Pagination>
        <Pagination.Item
          onClick={this.handlePrev}
          disabled={prev_page && prev_page > 0 ? false : true}>
            Previous { prev_page && prev_page > 0 ?  (`(Pg ${prev_page})`) : ''}
        </Pagination.Item>
        <Pagination.Item
          onClick={this.handleNext}
          disabled={next_page && next_page > 0 ? false : true}>
            Next { next_page && next_page > 0 ? (`(Pg ${next_page})`) : ''}
        </Pagination.Item>
      </Pagination>
    )
  }

  render() {
    return this.renderData(this.props.lists)
  }
}

UserLists.defaultProps = {
  user: {},
  lists: [],
  page: 1,
  prev_page: null,
  next_page: null
}

UserLists.propTypes = {
  user: PropTypes.object,
  lists: PropTypes.array,
  page: PropTypes.number,
  prev_page: PropTypes.number,
  next_page: PropTypes.number
}

function mapStateToProps(state) {
  return {
    user: state.user,
    lists: state.userLists.lists,
    page: state.userLists.page,
    prev_page: state.userLists.prev_page,
    next_page: state.userLists.next_page
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(listActions, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { pure: false }
)(UserLists)
