import initialState from './InitialStatsState'
import {
  FETCH_STATS,
  RECEIVE_STATS
} from '../../actions/stats/StatsActionTypes'

export default function (state = initialState, action) {
  let newState;
  switch (action.type) {
    case FETCH_STATS:
      newState = action
      break
    case RECEIVE_STATS:
      const stats = action.stats
      newState = { ...state, ...stats }
      break
    default:
      newState = state
  }
  return newState
}
