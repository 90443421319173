import React from 'react'
import { Form, FormControl } from 'react-bootstrap'

class GroupedInput extends React.Component {

  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(e) {
    const changeHandler = this.props.handleChange
    if (changeHandler) {
      changeHandler(e)
    }

    return null
  }

  render() {
    const { controlId, label, type, value, placeholder, disabled } = this.props
    return (
      <Form.Group controlId={controlId}>
        <Form.Label>{label}</Form.Label>
        <Form.Control
          type={type}
          value={value}
          disabled={disabled === 'true'}
          placeholder={placeholder}
          onChange={this.handleChange}
        />
        <FormControl.Feedback/>
      </Form.Group>
    )
  }

}

export default GroupedInput
