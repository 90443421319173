import * as types from './ListActionTypes'
import { API_ROOT_V2, API_KEY } from '../../../config/api-config'
import linkParser from 'parse-link-header'

export function receiveUpdatedList(obj) {
  return {
    type: types.RECEIVE_UPDATED_USER_LIST,
    list: obj.list
  }
}

export function receiveList(obj) {
  return {
    type: types.RECEIVE_USER_LIST,
    list: obj.list
  }
}

export function receiveLists(obj) {
  return {
    type: types.RECEIVE_USER_LISTS,
    lists: obj.lists,
    page: obj.page,
    next_page: obj.next_page,
    prev_page: obj.prev_page
  }
}

export function fetchList(user_id, list_id) {
  const endpoint = `${API_ROOT_V2}/users/${user_id}/lists/${list_id}`
  return async (dispatch) => {
    const res = await fetch(endpoint, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Authorization': `Token token=${API_KEY}`,
        'Accept': 'application/json'
      }
    })
    const json = await res.json()
    dispatch(receiveList({
      list: json.list
    }))
  }
}

export function fetchLists(user_id, page) {
  let next_page
  let prev_page
  let endpoint = `${API_ROOT_V2}/users/${user_id}/lists?all=true&page=${page}&per_page=24`

  return async (dispatch) => {
    const res = await fetch(endpoint, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Authorization': `Token token=${API_KEY}`,
        'Accept': 'application/json'
      }
    })
    let pagination_links = linkParser(res.headers.get('Link'))
    if (pagination_links) {
      let next = pagination_links['next']
      if (next) {
        next_page = Number(next['page'])
      }
      let prev = pagination_links['prev']
      if (prev) {
        prev_page = Number(prev['page'])
      }
    }
    const json = await res.json()
    dispatch(receiveLists({
      lists: json.lists,
      page: page,
      next_page: next_page,
      prev_page: prev_page
    }))
  }
}

export function updateList(user_id, list_id, body) {
  const endpoint = `${API_ROOT_V2}/users/${user_id}/lists/${list_id}`
  return async (dispatch) => {
    const res = await fetch(endpoint, {
      method: 'PUT',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Authorization': `Token token=${API_KEY}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
    const json = await res.json()
    dispatch(receiveUpdatedList({
      list: json.list
    }))
  }
}

export function reprocessList(user_id, list_id) {
  const endpoint = `${API_ROOT_V2}/users/${user_id}/lists/${list_id}/process`
  return async (dispatch) => {
    const res = await fetch(endpoint, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Authorization': `Token token=${API_KEY}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({force: true})
    })
    const json = await res.json()
    dispatch(receiveUpdatedList({
      list: json.list
    }))
  }
}

export function deleteList(user_id, list_id) {
  const endpoint = `${API_ROOT_V2}/users/${user_id}/lists/${list_id}`
  return async (dispatch) => {
    const res = await fetch(endpoint, {
      method: 'DELETE',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Authorization': `Token token=${API_KEY}`,
        'Accept': 'application/json'
      }
    })
    const json = await res.json()
    dispatch(receiveUpdatedList({
      list: json.list
    }))
  }
}
