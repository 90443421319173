import initialState from './InitialUserState'
import {
  FETCH_SINGLE_USER,
  RECEIVE_SINGLE_USER,
  UPDATE_USER_FAILED,
  RESET_UPDATE_USER_STATE,
  RESET_UPDATE_ERROR_STATE
} from '../../actions/users/UserActionTypes'

export default function user(state = initialState, action) {
  let newState;
  switch (action.type) {
    case FETCH_SINGLE_USER:
      newState = action
      break
    case RECEIVE_SINGLE_USER:
      const { user } = action
      newState = { ...user, userUpdated: action.user_updated }
      break
    case UPDATE_USER_FAILED:
      newState = {
        ...state,
        updateFailed: action.update_failed
      }
      break
    case RESET_UPDATE_USER_STATE:
      newState = {
        ...state,
        userUpdated: action.user_updated
      }
      break
    case RESET_UPDATE_ERROR_STATE:
      newState = {
        ...state,
        updateFailed: action.update_failed
      }
      break
    default:
      newState = state
  }

  return newState
}
