// Multiple user actions
export const FETCH_USERS = 'FETCH_USERS'
export const RECEIVE_USERS = 'RECEIVE_USERS'

// Single user actions
export const FETCH_SINGLE_USER = 'FETCH_SINGLE_USER'
export const RECEIVE_SINGLE_USER = 'RECEIVE_SINGLE_USER'
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED'
export const RESET_UPDATE_USER_STATE = 'RESET_UPDATE_USER_STATE'
export const RESET_UPDATE_ERROR_STATE = 'RESET_UPDATE_ERROR_STATE'
