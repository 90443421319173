import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as userActions from '../../actions/users/MultiUserActions'
import './UsersList.css'
import UserItem from './UserItem'
import PropTypes from 'prop-types'
import React from 'react'
import { Button,
         Form,
         ListGroup,
         Pagination } from 'react-bootstrap'

class UsersList extends React.Component {

  constructor(props) {
    super(props)
    this.state = { qry: null }
    this.handleNext = this.handleNext.bind(this)
    this.handlePrev = this.handlePrev.bind(this)
    this.handleUserSearchChange = this.handleUserSearchChange.bind(this)
    this.executeSearch = this.executeSearch.bind(this)
    this.renderNoUsers = this.renderNoUsers.bind(this)
    this.renderPagination = this.renderPagination.bind(this)
  }

  componentDidMount() {
     this.props.fetchUsers(this.props.page, this.state.qry)
  }

  handleNext() {
    this.props.fetchUsers(this.props.page + 1, this.state.qry)
  }

  handlePrev() {
    this.props.fetchUsers(this.props.page - 1, this.state.qry)
  }

  handleUserSearchChange(event) {
    this.setState({qry: event.target.value})
  }

  executeSearch(event) {
    event.preventDefault()
    this.props.fetchUsers(1, this.state.qry)
  }

  renderData(users) {
    users = users || []
    const userItems = users.map((user) =>
      <UserItem key={user.id.toString()}
                user={user} />
    )
    if (users.count === 0) {
      return this.renderNoUsers()
    } else {
      return (
        <div className="users-list">
          <Form inline className="search-form">
            <Form.Group controlId="userSearch">
              <Form.Control
                type="text"
                placeholder="Search Users"
                onChange={this.handleUserSearchChange}/>
            </Form.Group>
            <Button className="go-button" type="submit" onClick={this.executeSearch}>Go</Button>
          </Form>

          <ListGroup>
            {userItems}
          </ListGroup>

          {users.count === 0 ? '' : this.renderPagination()}
        </div>
      )
    }
  }

  renderNoUsers() {
    return <div className="">No Users</div>
  }

  renderPagination() {
    return (
      <Pagination>
        <Pagination.Item
          onClick={this.handlePrev}
          disabled={!this.props.prev_page ? true : false}>
            Previous
        </Pagination.Item>
        <Pagination.Item
          onClick={this.handleNext}
          disabled={!this.props.next_page ? true : false}>
            Next
        </Pagination.Item>
      </Pagination>
    )
  }

  render() {
    return (
      <div className="users-list-container">
        {this.renderData(this.props.users)}
      </div>
    )
  }
}

UsersList.propTypes = {
  users: PropTypes.array,
  page: PropTypes.number,
  prev_page: PropTypes.number,
  next_page: PropTypes.number
}

function mapStateToProps(state) {
  return {
    users: state.users.users,
    page: state.users.page,
    prev_page: state.users.prev_page,
    next_page: state.users.next_page
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(userActions, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersList)
