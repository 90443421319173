import React from 'react'
import { withRouter } from "react-router-dom"
import AuthService from '../../services/auth/AuthService'
import { Nav } from 'react-bootstrap'
import './LoginNavLink.css'

const LoginNavLink = (props) => {

  const isLoggedIn = AuthService.isLoggedIn()

  const login = () => {
    props.history.push('/login')
  }

  const logout = () => {
    AuthService.logout()
    props.history.push('/login')
  }

  const onClick = (eventKey: any, event: SyntheticEvent) => {
    isLoggedIn ? logout() : login()
  }

  const buttonLabel = isLoggedIn ? 'Sign out' : 'Sign in'

  return (
    <Nav.Link className="login-link" onSelect={onClick} eventKey="/login">{buttonLabel}</Nav.Link>
  )
}

export default withRouter(LoginNavLink)
