const state = {
  id: 0,
  username: '',
  email: '',
  password: '',
  first_name: '',
  last_name: '',
  phone: '',
  address: '',
  address_two: '',
  city: '',
  state: '',
  zip: '',
  num_lists: 0,
  num_properties: 0,
  requests_remaining: 0,
  has_subscribed: false,
  subscription_active: false,
  subscription_expires_at: '',
  purchases: []
}

export default state
