import * as types from './StatsActionTypes'
import { API_ROOT_V2, API_KEY } from '../../config/api-config'

export function receiveStats(stats) {
  return {
    type: types.RECEIVE_STATS,
    stats: stats
  }
}

export function fetchStats(start_date, end_date) {
  return dispatch => {
    return fetch(`${API_ROOT_V2}/stats?start_date=${start_date}&end_date=${end_date}`, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Authorization': `Token token=${API_KEY}`,
        'Accept': 'application/json'
      }
    })
    .then(res => res.json())
    .then(json => {
      dispatch(receiveStats(json))
    })
    .catch(err => {
      dispatch(receiveStats(null))
    })
  }
}
