import * as types from './UserActionTypes'
import { API_ROOT_V2, API_KEY } from '../../config/api-config'

const removeIdFromUser = (user) => {
  delete user.id
  return user
}

export function receiveUser(user, updated = false) {
  return {
    type: types.RECEIVE_SINGLE_USER,
    user: user,
    user_updated: updated
  }
}

export function resetUserUpdatedState() {
  return {
    type: types.RESET_UPDATE_USER_STATE,
    user_updated: false
  }
}

export function clearUserUpdatedState() {
  return (dispatch) => {
    dispatch(resetUserUpdatedState())
  }
}

export function updateUserError() {
  return {
    type: types.UPDATE_USER_FAILED,
    update_failed: true
  }
}

export function resetErrorState() {
  return {
    type: types.RESET_UPDATE_ERROR_STATE,
    update_failed: false
  }
}

export function clearUpdateFailedState() {
  return (dispatch) => {
    dispatch(resetErrorState())
  }
}

export function fetchUser(id) {
  return async (dispatch) => {
    try {
      const res = await fetch(`${API_ROOT_V2}/users/${id}`, {
        method: 'GET',
        mode: 'cors',
        credentials: 'include',
        headers: {
          'Authorization': `Token token=${API_KEY}`,
          'Accept': 'application/json'
        }
      })
      const json = await res.json()
      dispatch(receiveUser(json.user))
    }
    catch (err) {
      console.log(err)
      dispatch(updateUserError())
    }
  }
}

export function updateUser(user) {
  return async (dispatch) => {
    try {
      const res = await fetch(`${API_ROOT_V2}/users/${user.id}`, {
        method: 'PUT',
        mode: 'cors',
        credentials: 'include',
        headers: {
          'Authorization': `Token token=${API_KEY}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(removeIdFromUser(user))
      })
      const json = await res.json()
      let user_1 = json.user
      if (user_1.id === null || user_1.id === undefined) {
        dispatch(updateUserError())
      }
      else {
        dispatch(receiveUser(user_1, true))
      }
    }
    catch (err) {
      dispatch(updateUserError())
    }
  }
}
