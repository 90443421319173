import React from 'react'
import { Badge, Button, Card } from 'react-bootstrap'
import './UserListsItem.css'

const renderDeleteButtons = (props, list) => {
  if (list.deleted) {
    return (
      <Button type="submit" onClick={() => props.recoverList(list.id)} className="list-button" size="sm" variant="outline-success">Recover</Button>
    )
  } else {
    return (
      <Button type="submit" onClick={() => props.deleteList(list.id)} className="list-button" size="sm" variant="outline-danger">Delete</Button>
    )
  }
}

export default (props) => {
  const { list } = props
  return (
    <Card>
      <Card.Header>
        <Badge pill variant={list.is_ready ? 'success' : 'danger'}>{list.id}</Badge> {list.name} <br/>
      </Card.Header>
      <Card.Body>
        <Card.Text>
          Properties: {list.num_properties} <br/>
          Created: {list.created_at}
        </Card.Text>
      </Card.Body>
      <Card.Footer>
        <Button type="submit" onClick={() => props.reRunList(list.id)} className="list-button" size="sm" variant="outline-primary">Process</Button>
        {renderDeleteButtons(props, list)}
      </Card.Footer>
    </Card>
  )
}