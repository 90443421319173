import React from 'react'
import './UserPurchases.css'
import { Table } from 'react-bootstrap'
import UserPurchase from './UserPurchase.js'
import { Form } from 'react-bootstrap'

function UserPurchases(props) {
  if (!props.purchases) {
    return ("")
  }
  const purchases = props.purchases.map((purchase) =>
    <UserPurchase key={purchase.id.toString()} purchase={purchase} />
  )
  return (
    <Form.Group
      controlId="userPurchases"
    >
      Purchases
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Product ID</th>
            <th>Receipt</th>
            <th>Transaction ID</th>
            <th>Play Store Order ID</th>
            <th>Created At</th>
          </tr>
        </thead>
        <tbody>
          {purchases}
        </tbody>
      </Table>
    </Form.Group>
  )
}

export default UserPurchases
