import initialState from '../initialState'
import * as types from '../../actions/login/LoginActionTypes'

export default (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case types.LOGIN_USER:
      console.log('LOGIN_USER');
      newState = action
      break
    case types.RECEIVE_USER_ACCT:
      console.log('RECEIVE_USER_ACCT');
      newState = {
        ...state,
        token: action.token,
        authedAcct: action.authedAcct
      }
      break
    case types.LOGIN_INVALID:
      console.log('LOGIN_INVALID');
      newState = {
        ...state,
        invalidLogin: action.invalidLogin
      }
      break
    case types.RESET_LOGIN_STATE:
      console.log('RESET_LOGIN_STATE');
      newState = {
        ...state,
        invalidLogin: action.invalidLogin,
        token: action.token || null,
        authedAcct: action.authedAcct || null
      }
      break
    default:
      newState = state
  }
  return newState
}
