import initialState from '../../initialState'
import { FETCH_USER_LISTS,
         RECEIVE_USER_LISTS,
         FETCH_USER_LIST,
         UPDATE_USER_LIST,
         RECEIVE_USER_LIST,
         RECEIVE_UPDATED_USER_LIST } from '../../../actions/users/lists/ListActionTypes'

export default function (state = initialState, action) {
  let newState;
  switch (action.type) {
    case FETCH_USER_LISTS:
      newState = action
      break
    case RECEIVE_USER_LISTS:
      newState = {
        ...state,
        lists: action.lists,
        page: action.page,
        prev_page: action.prev_page,
        next_page: action.next_page
      }
      break
    case FETCH_USER_LIST:
      newState = action
      break
    case UPDATE_USER_LIST:
      newState = action
      break
    case RECEIVE_USER_LIST:
      newState = {
        ...state,
        list: action.list
      }
      break
    case RECEIVE_UPDATED_USER_LIST:
      const { lists } = state
      const { list } = action
      lists.splice(lists.findIndex((e) => e.id === list.id), 1, action.list)
      newState = {
        ...state,
        lists: lists
      }
      break
    default:
      newState = state
  }
  return newState
}
