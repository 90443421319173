import React from 'react'
import AuthService from '../../services/auth/AuthService'
import { Redirect, Route } from 'react-router-dom'

export default ({ component: Component, ...rest }) => {
  console.log("rest.routes", rest.routes);
  
  if (!AuthService.isLoggedIn()) {
    return <Redirect
      to={{
        pathname: '/login'
      }}
    />
  }

  return <Route {...rest} render={rest.render} />
}
