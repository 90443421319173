import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import * as statsActions from '../../actions/stats/StatsActions'
import './Stats.css'
import { Card, CardColumns, Form, Button, Row, Col } from 'react-bootstrap'

class Stats extends React.Component {

  constructor(props) {
    super(props)
    this.startDateChanged = this.startDateChanged.bind(this)
    this.endDateChanged = this.endDateChanged.bind(this)
    this.refresh = this.refresh.bind(this)
  }

  componentDidMount() {
     this.props.fetchStats(this.props.start_date, this.props.end_date)
  }

  startDateChanged = (e) => {
    this.setState({start_date: e.target.value})
  }

  endDateChanged = (e) => {
    this.setState({end_date: e.target.value})
  }

  refresh = (e) => {
    e.preventDefault()
    let start = this.state.start_date || this.props.start_date
    let end = this.state.end_date || this.props.end_date
    this.props.fetchStats(start, end)
  }

  renderData(stats) {
    stats = stats || []
    const mappedStats = stats.map((stat) =>
      <Card key={stat.name}>
        <Card.Header>{stat.name}</Card.Header>
        <Card.Body>
          <Card.Text>
            <span className="stat-large">{stat.value}</span>
          </Card.Text>
        </Card.Body>
      </Card>
    )
    return (
      <div id="container">
        <Form>
          <Form.Group as={Row}>
            <Form.Label column sm={2}>Start Date</Form.Label>
            <Col sm={10}>
              <Form.Control type="text" placeholder={this.props.start_date} id="start_date" onChange={this.startDateChanged}/>
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={2}>End Date</Form.Label>
            <Col sm={10}>
              <Form.Control type="text" placeholder={this.props.end_date} id="end_date" />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="formSubmit">
            <Col sm={10}>
              <Button type="submit" onClick={this.refresh}>Submit</Button>
            </Col>
          </Form.Group>
        </Form>
        <CardColumns>
          {mappedStats}
        </CardColumns>
      </div>
    )
  }

  renderNoStats() {
    return <div className="">No Stats To Display</div>
  }

  render() {
    return (
      <div>
        {this.renderData(this.props.stats)}
      </div>
    )
  }
}

Stats.defaultProps = {
  stats: [],
  start_date: new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().slice(0, 10),
  end_date: new Date().toISOString().slice(0, 10)
}

Stats.propTypes = {
  stats: PropTypes.array,
  start_date: PropTypes.string,
  end_date: PropTypes.string
}

function mapStateToProps(state) {
  console.log(state);
  return {
    stats: state.stats.stats,
    start_date: state.start_date,
    end_date: state.end_date
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(statsActions, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { pure: false }
)(Stats)
