import React from 'react'
import './UserItem.css'
import { LinkContainer } from 'react-router-bootstrap'
import { ListGroup, Badge } from 'react-bootstrap'

const UserItem = (props) => {
  const { user: { email, id, has_subscribed, subscription_active } } = props
  
  return (
    <LinkContainer to={"/users/" + id}>
      <ListGroup.Item>
        <span className="title">
          {email.length > 25 ? email.substr(0,25) + '...' : email}
        </span>
        <span className="sub-title">
          Has Subscribed: <Badge pill variant={has_subscribed ? 'success' : 'danger'}>{has_subscribed ? 'Yes' : 'No'}</Badge>
        </span>
        {
          has_subscribed && subscription_active ? (
            <span className="sub-title">
              Subscription Active: <Badge pill variant={has_subscribed && subscription_active ? 'success' : 'danger'}>{has_subscribed && subscription_active ? 'Yes' : 'No'}</Badge>
            </span>
          ) : ''
        }
      </ListGroup.Item>
    </LinkContainer>
  )
}

export default UserItem
