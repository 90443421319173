import initialState from '../initialState'
import { FETCH_USERS, RECEIVE_USERS } from '../../actions/users/UserActionTypes'

export default function users(state = initialState, action) {
  let newState;
  switch (action.type) {
    case FETCH_USERS:
      newState = action
      break
    case RECEIVE_USERS:
      newState = {
        ...state,
        users: action.users,
        page: action.page,
        prev_page: action.prev_page,
        next_page: action.next_page
      }
      break
    default:
      newState = state
  }
  return newState
}
