import UserDetail from "../components/users/UserDetail"
import UserLists from "../components/users/lists/UserLists"
import Stats from "../components/stats/Stats"
import AccountLogin from "../components/login/AccountLogin"

export default [
  {
    path: "/",
    component: AccountLogin,
    exact: true
  },
  // {
  //   path: "/users",
  //   component: UserList,
  //   private: true,
  //   exact: true
  // },
  {
    path: "/users/:id",
    component: UserDetail,
    private: true,
    exact: true
  },
  {
    path: "/users/:id/lists",
    component: UserLists,
    private: true,
    exact: true
  },
  {
    path: "/stats",
    component: Stats,
    private: true,
    exact: true
  },
  {
    path: "/login",
    component: AccountLogin,
    exact: true
  }
  // {
  //   path: "/tacos",
  //   component: Tacos,
  //   routes: [
  //     {
  //       path: "/tacos/bus",
  //       component: Bus
  //     },
  //     {
  //       path: "/tacos/cart",
  //       component: Cart
  //     }
  //   ]
  // }
]
