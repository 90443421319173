const D4D_AUTH_TOKEN_KEY = 'd4d_auth_token'
const D4D_USER_KEY = 'd4d_user'

class AuthService {

  static loginUser = (user, token) => {
    localStorage.setItem(D4D_AUTH_TOKEN_KEY, token)
    localStorage.setItem(D4D_USER_KEY, user)
  }

  static logout = () => {
    localStorage.removeItem(D4D_AUTH_TOKEN_KEY)
    localStorage.removeItem(D4D_USER_KEY)
  }

  static isLoggedIn = () => {
    return localStorage.getItem(D4D_AUTH_TOKEN_KEY) &&
                     localStorage.getItem(D4D_USER_KEY)
  }

  static acctToken = () => {
    return localStorage.getItem(D4D_AUTH_TOKEN_KEY)
  }

  static authedAcct = () => {
    return localStorage.getItem(D4D_USER_KEY)
  }

}

export default AuthService
