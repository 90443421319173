import React from 'react'
import './Header.css'
import LoginNavLink from './login/LoginNavLink'
import StatsNavLink from './stats/StatsNavLink'
import UsersNavLink from './users/UsersNavLink'
import { Nav, Navbar } from 'react-bootstrap'

export default () => {
  return (
    <Navbar variant="dark" expand="lg">
      <Navbar.Brand href="/">D4D Admin</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto" defaultActiveKey="/login">
          <UsersNavLink />
          <StatsNavLink />
          <LoginNavLink />
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

