import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import * as userActions from '../../actions/users/SingleUserActions'
import './UserDetail.css'
import UserPurchases from './purchases/UserPurchases.js'
import EmailInput from '../form/EmailInput'
import GroupedInput from '../form/GroupedInput'
import { Button, Container, Row, Col } from 'react-bootstrap'
import NotificationSystem from 'react-notification-system'

class UserDetail extends React.Component {

  constructor(props) {
    super()
    this.notificationSystem = React.createRef()
    this.state = {
      tempForm: {}
    }
    this.handleDataChanged = this.handleDataChanged.bind(this)
    this.handleEmailChanged = this.handleEmailChanged.bind(this)
    this.handleFirstNameChanged = this.handleFirstNameChanged.bind(this)
    this.handleLastNameChanged = this.handleLastNameChanged.bind(this)
    this.handlePasswordChanged = this.handlePasswordChanged.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.viewUserLists = this.viewUserLists.bind(this)
    this.userRequestsRemaining = this.userRequestsRemaining.bind(this)
  }

  componentDidMount() {
    this.props.fetchUser(this.props.match.params.id)
  }

  componentDidUpdate(prevProps, prevState) {
    const isSameId = this.props.match.params.id === prevProps.match.params.id
    if (!isSameId) {
      this.setState({ tempForm: {} })
      this.props.fetchUser(this.props.match.params.id)
    } else if (this.props.updateFailed) {
      this.userUpdateFailedNotification()
      this.props.clearUpdateFailedState()
    } else {
      if (this.props.userUpdated) {
        this.userUpdatedNotification()
        this.props.clearUserUpdatedState()
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const isSameId = this.props.match.params.id === nextProps.match.params.id
    console.log('isSameId', isSameId);
    
    return true // !isSameId
  }

  userUpdatedNotification = () => {
    const notification = this.notificationSystem.current;
    notification.clearNotifications()
    notification.addNotification({
      message: 'User account updated',
      level: 'success',
      position: 'tc'
    })
    this.setState({ tempForm: {} })
  }

  userUpdateFailedNotification = () => {
    const notification = this.notificationSystem.current;
    notification.clearNotifications()
    notification.addNotification({
      message: 'User account was not updated due to an error',
      level: 'error',
      position: 'tc'
    })
  }

  handleDataChanged(e) {
    const value = e.target.value
    const controlId = e.target.id
    switch (controlId) {
      case 'userEmail':
        this.handleEmailChanged(value)
        break
      case 'userFirstName':
        this.handleFirstNameChanged(value)
        break
      case 'userLastName':
        this.handleLastNameChanged(value)
        break
      case 'userPassword':
        this.handlePasswordChanged(value)
        break
      case 'userPhone':
        this.handlePhoneChanged(value)
        break
      case 'userAddress':
        this.handleAddressChanged(value)
        break
      case 'userAddressTwo':
        this.handleAddressTwoChanged(value)
        break
      case 'userCity':
        this.handleCityChanged(value)
        break
      case 'userState':
        this.handleStateChanged(value)
        break
      case 'userZip':
        this.handleZipChanged(value)
        break
      case 'userRequestsRemaining':
        this.handleRequestsRemainingChanged(value)
        break
      case 'userSubscriptionExpiration':
        this.handleSubscriptionExpirationChange(value)
        break
      default:
        console.log("UserDetail::handleDataChanged: controlId not handled - " + controlId)
    }
  }

  handleEmailChanged(value) {
    const tempForm = { ...this.state.tempForm }
    tempForm.email = value
    this.setState({ tempForm: tempForm })
  }

  handleFirstNameChanged(value) {
    const tempForm = { ...this.state.tempForm }
    tempForm.first_name = value
    this.setState({ tempForm: tempForm })
  }

  handleLastNameChanged(value) {
    const tempForm = { ...this.state.tempForm }
    tempForm.last_name = value
    this.setState({ tempForm: tempForm })
  }

  handlePasswordChanged(value) {
    const tempForm = { ...this.state.tempForm }
    tempForm.password = value
    this.setState({ tempForm: tempForm })
  }

  handlePhoneChanged(value) {
    const tempForm = { ...this.state.tempForm }
    tempForm.phone = value
    this.setState({ tempForm: tempForm })
  }

  handleAddressChanged(value) {
    const tempForm = { ...this.state.tempForm }
    tempForm.address = value
    this.setState({ tempForm: tempForm })
  }

  handleAddressTwoChanged(value) {
    const tempForm = { ...this.state.tempForm }
    tempForm.address_two = value
    this.setState({ tempForm: tempForm })
  }

  handleCityChanged(value) {
    const tempForm = { ...this.state.tempForm }
    tempForm.city = value
    this.setState({ tempForm: tempForm })
  }

  handleStateChanged(value) {
    const tempForm = { ...this.state.tempForm }
    tempForm.state = value
    this.setState({ tempForm: tempForm })
  }

  handleZipChanged(value) {
    const tempForm = { ...this.state.tempForm }
    tempForm.zip = value
    this.setState({ tempForm: tempForm })
  }

  handleRequestsRemainingChanged(value) {
    const tempForm = { ...this.state.tempForm }
    console.log("tempForm", tempForm);
    tempForm.requests_remaining = value
    console.log("tempForm", tempForm);
    this.setState({ tempForm: tempForm })
  }

  handleSubscriptionExpirationChange(value) {
    const tempForm = { ...this.state.tempForm }
    tempForm.sub_expire_date = value
    this.setState({ tempForm: tempForm })
  }

  onSubmit(e) {
    e.preventDefault()
    let user = { ...this.state.tempForm }
    if (user.password) {
      user.password_confirmation = user.password
    }
    user.id = this.props.id
    this.props.updateUser(user)
  }

  viewUserLists(e) {
    e.preventDefault()
    let user_id = this.props.id
    this.props.history.push(`/users/${user_id}/lists`)
  }

  userRequestsRemaining() {
    const user = this.props || {}
    const tempForm = this.state.tempForm || {}
    console.log("tempForm.requests_remaining", tempForm.requests_remaining);
    if (tempForm.requests_remaining !== undefined) {
      console.log("Using tempForm value", tempForm.requests_remaining);
      return tempForm.requests_remaining
    } else if (user.requests_remaining && user.requests_remaining > 0) {
      console.log("Using user value", user.requests_remaining);
      return user.requests_remaining
    } else {
      console.log("Using empty value");
      return ""
    }
  }

  render() {
    const user = this.props || {}
    const tempForm = this.state.tempForm || {}
    return (
      <div className="details-container">
        <div className="user-details">
          <form id="user-detail-form">
            <Container>
              <Row>
                <Col>
                  <GroupedInput
                    controlId="userId"
                    label="User ID"
                    type="text"
                    value={user.id}
                    disabled="true" />
                </Col>
                <Col>
                  <GroupedInput
                    controlId="userCreatedAt"
                    label="Account Creation Date"
                    type="text"
                    value={user.createdAt}
                    disabled="true" />
                </Col>
              </Row>

              <Row>
                <Col>
                  <GroupedInput
                    controlId="userCreatedAt"
                    label="Account Updated Date"
                    type="text"
                    value={user.updatedAt}
                    disabled="true" />
                </Col>
                <Col>
                  <GroupedInput
                    controlId="userUsername"
                    label="Username"
                    type="text"
                    value={user.username}
                    placeholder="Username"
                    disabled="true" />
                </Col>
              </Row>

              <Row>
                <Col>
                  <EmailInput
                    controlId="userEmail"
                    label="Email"
                    type="text"
                    value={tempForm.email ? tempForm.email : user.email}
                    placeholder="Email"
                    handleChange={this.handleDataChanged} />
                </Col>
                <Col>
                  <GroupedInput
                    controlId="userPassword"
                    label="Update Password"
                    type="text"
                    placeholder={user.password ? user.password : "Enter New User Password"}
                    handleChange={this.handleDataChanged} />
                </Col>
              </Row>

              <Row>
                <Col>
                  <GroupedInput
                    controlId="userFirstName"
                    label="First Name"
                    type="text"
                    value={tempForm.first_name ? tempForm.first_name : user.first_name}
                    placeholder="First Name"
                    handleChange={this.handleDataChanged} />
                </Col>
                <Col>
                  <GroupedInput
                    controlId="userLastName"
                    label="Last Name"
                    type="text"
                    value={tempForm.last_name ? tempForm.last_name : user.last_name}
                    placeholder="Last Name"
                    handleChange={this.handleDataChanged} />
                </Col>
              </Row>

              <Row>
                <Col>
                  <GroupedInput
                    controlId="userPhone"
                    label="Phone"
                    type="text"
                    value={tempForm.phone ? tempForm.phone : user.phone}
                    placeholder="Phone"
                    handleChange={this.handleDataChanged} />
                </Col>
                <Col>
                  <GroupedInput
                    controlId="userAddress"
                    label="Address"
                    type="text"
                    value={tempForm.address ? tempForm.address : user.address}
                    placeholder="Address"
                    handleChange={this.handleDataChanged} />
                </Col>
              </Row>

              <Row>
                <Col>
                  <GroupedInput
                    controlId="userAddressTwo"
                    label="Address Line Two"
                    type="text"
                    value={tempForm.address_two ? tempForm.address_two : user.address_two}
                    placeholder="Address Line Two"
                    handleChange={this.handleDataChanged} />
                </Col>
                <Col>
                  <GroupedInput
                    controlId="userCity"
                    label="City"
                    type="text"
                    value={tempForm.city ? tempForm.city : user.city}
                    placeholder="City"
                    handleChange={this.handleDataChanged} />
                </Col>
              </Row>

              <Row>
                <Col>
                  <GroupedInput
                    controlId="userState"
                    label="State"
                    type="text"
                    value={tempForm.state ? tempForm.state : user.state}
                    placeholder="State"
                    handleChange={this.handleDataChanged} />
                </Col>
                <Col>
                  <GroupedInput
                    controlId="userZip"
                    label="Zip"
                    type="text"
                    value={tempForm.zip ? tempForm.zip : user.zip}
                    placeholder="Zip"
                    handleChange={this.handleDataChanged} />
                </Col>
              </Row>
 
              <Row>
                <Col>
                  <GroupedInput
                    controlId="userNumLists"
                    label="Lists"
                    type="text"
                    value={user.num_lists}
                    disabled="true"
                    handleChange={this.handleDataChanged} />

                  <Button type="submit" onClick={this.viewUserLists}>View Lists</Button>
                </Col>
                <Col>
                  <GroupedInput
                    controlId="userNumProperties"
                    label="Properties"
                    type="text"
                    value={user.num_properties}
                    disabled="true"
                    handleChange={this.handleDataChanged} />
                </Col>
              </Row>

              <Row>
                <Col>
                  <GroupedInput
                    controlId="userRequestsRemaining"
                    label="Requests Remaining"
                    type="text"
                    value={this.userRequestsRemaining()}
                    handleChange={this.handleDataChanged} />
                </Col>
                <Col>
                  <GroupedInput
                    controlId="userHasSubscribed"
                    label="Has Subscribed"
                    type="text"
                    value={user.has_subscribed}
                    disabled="true"
                    handleChange={this.handleDataChanged} />
                </Col>
              </Row>
              
              <Row>
                <Col>
                  <GroupedInput
                    controlId="userSubscriptionActive"
                    label="Subscription Active"
                    type="text"
                    value={user.subscription_active}
                    disabled="true"
                    handleChange={this.handleDataChanged} />
                </Col>
                <Col>
                  <GroupedInput
                    controlId="userSubscriptionExpiration"
                    label="Subscription Expiration"
                    type="text"
                    value={tempForm.sub_expire_date ? tempForm.sub_expire_date : user.subscription_expires_at}
                    handleChange={this.handleDataChanged} />
                </Col>
              </Row>

              <Row>
                <Col>
                  <GroupedInput
                    controlId="userSubscriptionStatus"
                    label="Subscription Status"
                    type="text"
                    value={user.subscription_status}
                    disabled="true"
                    handleChange={this.handleDataChanged} />
                </Col>
              </Row>
            </Container>

            <UserPurchases purchases={user.purchases} />

            <Button type="submit" onClick={this.onSubmit}>Submit</Button>

          </form>
        </div>

        <NotificationSystem ref={this.notificationSystem} />
      </div>
    )
  }
}

UserDetail.propTypes = {
  userActions: PropTypes.object,
  userUpdated: PropTypes.bool,
  updateFailed: PropTypes.bool,
  id: PropTypes.number,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  username: PropTypes.string,
  email: PropTypes.string,
  password: PropTypes.string,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  phone: PropTypes.string,
  address: PropTypes.string,
  address_two: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
  num_lists: PropTypes.number,
  num_properties: PropTypes.number,
  requests_remaining: PropTypes.number,
  has_subscribed: PropTypes.bool,
  subscription_active: PropTypes.bool,
  subscription_expires_at: PropTypes.string,
  subscription_status: PropTypes.number,
  purchases: PropTypes.array
}

function mapStateToProps(state) {
  const user = state.user || {}
  return {
    userUpdated: user.userUpdated || false,
    updateFailed: user.updateFailed || false,
    id: user.id || 0,
    createdAt: user.created_at || '',
    updatedAt: user.updated_at || '',
    username: user.username || '',
    email: user.email || '',
    password: '',
    first_name: user.first_name || '',
    last_name: user.last_name || '',
    phone: user.phone || '',
    address: user.address || '',
    address_two: user.address_two || '',
    city: user.city || '',
    state: user.state || '',
    zip: user.zip || '',
    num_lists: user.num_lists || 0,
    num_properties: user.num_properties || 0,
    requests_remaining: user.requests_remaining || 0,
    has_subscribed: user.has_subscribed || false,
    subscription_active: user.subscription_active || false,
    subscription_expires_at: user.subscription_expires_at || '',
    subscription_status: user.subscription_status || '',
    purchases: user.purchases || []
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(userActions, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserDetail)
