import React from 'react'
import { withRouter } from "react-router-dom"
import AuthService from '../../services/auth/AuthService'
import { Nav } from 'react-bootstrap'
import './StatsNavLink.css'

const StatsNavLink = (props) => {

  const isLoggedIn = AuthService.isLoggedIn()

  const onClick = (eventKey: any, event: SyntheticEvent) => {
    props.history.push('/stats')
  }

  return (
    isLoggedIn ? <Nav.Link className="stats-link" onSelect={onClick} eventKey="/stats">Stats</Nav.Link> : ''
  )
}

export default withRouter(StatsNavLink)
