import React from 'react'
import GroupedInput from './GroupedInput'

class EmailInput extends React.Component {

  constructor(props) {
    super(props)
    this.getValidationState = this.getValidationState.bind(this)
  }

  getValidationState() {
    console.log("EmailInput::getValidationState")
    const value = this.props.value
    if (value === null || value === undefined || value.length === 0) return null
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (re.test(String(value).toLowerCase())) return 'success'
    else return 'error'
  }

  render() {
    const props = this.props
    const controlId = props.controlId
    const label = props.label
    const type = props.type
    const value = props.value
    const placeholder = props.placeholder
    const disabled = props.disabled
    const handleChange = props.handleChange
    return (
      <GroupedInput
        controlId={controlId}
        label={label}
        type={type}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        getValidationState={this.getValidationState}
        handleChange={handleChange} />
    )
  }

}

export default EmailInput
